<template>
  <div>

    <HistoryCurve :power="power" :max-power="maxPower" :resistance="resistance" :curve-line-color="theme.curveLineColor"
        :curve-color="theme.curveColor" @click="onCurveClick">
    </HistoryCurve>

    <!-- 曲线2 -->
    <div
        ref="voltageTwo"
        class="voltage-two"
        style="width:100%;height:calc(20vh)"
    >
      <div class="voltage-two-list" v-for="(value, index) in diyVoltage" :key="index">
        <van-icon
            name="plus"
            class="voltage-plus"
            size="15"
            :color="theme.curveColor"
        />
        <van-slider
            v-model="diyVoltage[index]"
            vertical
            :step="100"
            :min="-limitMaxVoltage"
            :max="-limitMinVoltage"
            bar-height="4px"
            active-color="#E6E7E8"
            :inactive-color="theme.curveColor"
            @input="onSlideChange"

        >
          <template #button>
            <!-- <img
              class="slider-button"
              src="@/assets/icons/icon_slider_button.png"
            /> -->
            <div
                class="slider-button"
                :style="`border: 2px solid ${theme.curveColor}`"
            ></div>
          </template>
        </van-slider>
        <van-icon
            name="minus"
            size="15"
            class="voltage-reduce"
            :color="theme.curveColor"
        />
      </div>
    </div>

    <div class="voltage-three">
      <div class="voltage-number" v-for="index of 8" :key="index">
        {{ index }}s
      </div>
    </div>

    <div style="margin-top:16px;">
      <div class="custom-curve">
        <van-button
            v-for="(mode, index) in topModeList"
            :key="mode.id"
            class="custom-item"
        >
          {{ mode.modeName }}
        </van-button>
      </div>
      <div class="custom-curve">
        <van-button
            v-for="(mode, index) in bottomModeList"
            :key="mode.id"
            class="custom-item"
        >
          {{ mode.modeName }}
        </van-button>
      </div>

    </div>
  </div>

</template>

<script>
import HistoryCurve from "@/views/HistoryCurve.vue";
import { calcMaxPower, calcVoltageFloorInt, toFloat } from "@/util/utils";
import { chartConfig, d019ChartConfig } from "@/config/echarts-data";
import store from "@/store";

export default {
  name: "ThemeTemplate",
  components: { HistoryCurve },
  data() {
    return {
      limitMaxVoltage: 3500,
      limitMinVoltage: 350,

      power: [3.33, 3.33, 3.33, 3.33, 3.33, 3.33, 3.33, 3.33],
      diyVoltage: [-2000, -2000, -2000, -2000, -2000, -2000, -2000, -2000],
      maxPower: 12,
      resistance: 1.2,
      value2: 50,

      theme: {
        themeMainColor: "#3fa7bc", //主色
        dropDownActiveColor: "#3fa7bc", //下拉框选中的颜色

        syncButtonColor: "#3fa7bc", //同步按钮颜色

        curveColor: "#86A1A9", //曲线颜色
        curveLineColor: ["rgba(192, 222, 226, 1)", "rgba(246, 250, 250, 1)"], //曲线范围的渐变色

        modeNormalBackground: "#3290a0", //推荐按钮背景颜色
        modeNormalTextColor: "#ffffff", //推荐按钮字体颜色
        modeActiveBackground: "#d5efee", //推荐按钮选中背景色
        modeActiveTextColor: "#3290a0", //推荐按钮选中文字颜色

        percentRingUnusedColor: ["#3290A0", "#9DCCD0"], //读取设备圆环的渐变色
        percentRingUseColor: "#F1F2F2", //读取设备圆环的已使用的背景色
        percentRingTextColor: "#3290A0", //圆环文字颜色

        cancelButtonColor: "#e8eff2",

        tabButtonActiveColor: "#3fa7bc",
        tabButtonInactiveColor: "#e8eff2",

        preheatActiveColor: "#b9c4c6",
        preheatInactiveColor: "#a7a9ac"
      },

      topModeList: [
        {
          id: 1,
          modeName: "THC-A"
        },
        {
          id: 2,
          modeName: "THC-B"
        },
      ],

      bottomModeList: [
        {
          id: 1,
          modeName: "THC-A"
        },
        {
          id: 2,
          modeName: "THC-B"
        },
        {
          id: 3,
          modeName: "THC-C"
        },
        {
          id: 4,
          modeName: "THC-D"
        }
      ],

      curveChart: null
    };
  },
  methods: {

    onCurveClick() {

    },
    onSlideChange(e) {
      console.log(JSON.stringify(e));
    },

    refreshVoltageCurve() {
      const voltageData = chartConfig();

      voltageData.color[0] = this.curveColor;
      voltageData.areaStyle.color = {
        color: new graphic.LinearGradient(0, 0, 0, 1, this.curveLineColor.map((item, index) => {

        }))
      };
      // console.log(voltageData.series[0].data)
      voltageData.yAxis.min = 0.01;
      voltageData.yAxis.max = parseFloat(this.maxPower.toFixed(2));

      this.$refs.voltageTwo.setOption(voltageData, true);
    }
  },
  mounted() {
  }
};
</script>


<style scoped lang="less">

.voltage-two {
  display: flex;
  margin: 10px 0 10px 0;

  .voltage-two-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .voltage-plus {
      margin-bottom: 10px;
    }

    .voltage-reduce {
      margin-top: 10px;
    }
  }

  /deep/ .van-slider {
    margin: 10px auto;
  }

  .slider-button {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ffffff;
  }

}

.voltage-three {
  display: flex;
  width: 100%;

  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
}

.custom-curve {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-top:8px;

  .custom-item {
    flex:1;
    height: 44px;
    line-height: 44px;
    border-radius: 6px;
    font-size: 16px;
    text-align: center;
    padding: 0;
    word-break: break-all;
    line-height: 13px;
    background: var(--modeNormalBackground);
    color: var(--modeNormalTextColor);
  }

  .recommended-button-active {
    background: var(--modeActiveBackground) !important;
    color: var(--modeActiveTextColor) !important;
  }
}

</style>